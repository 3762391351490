import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Button,
    Popover,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Box,
    Grid,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Backdrop,
    CircularProgress,
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { LoadingButton } from '@material-ui/lab';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

// Pdf
import { PDFViewer } from '@react-pdf/renderer';
import PdfTicket from '../../../components/PdfTicket';

// components
import LoadingScreen from '../../../components/loading/LoadingScreen';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import AutocompleteData from '../../../components/autocomplete/AutocompleteData';

// utils
import { fPhoneNumber2 } from '../../../utils/formatNumber';

import CabeceraTabla from './CabeceraTabla';
import BuscadorClientes from './BuscadorClientes';

// Servicios
import {
    obtenerSucursales,
    obtenerClienteNumeroCliente,
    obtenerClienteCodigo,
    obtenerClienteNombre,
    obtenerClienteTelefono,
    obtenerTicket,
    obtenerNipCliente,
    registrarRegistro
} from '../../../services/administrador/tickets';

// Importacion de conector impresora
// const { ConectorPluginV3 } = require("./ConectorJavaScript");

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'numero_cliente', label: 'Número de Cliente', alignRight: false },
    { id: 'codigo', label: 'Código', alignRight: false },
    { id: 'nombre_cliente', label: 'Nombre de Cliente', alignRight: false },
    { id: 'telefono', label: 'Teléfono', alignRight: false },
    { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.fields.nom.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function AdministradorTickets() {
    const [open, setOpen] = useState(null);
    const [page, setPage] = useState(0);
    // eslint-disable-next-line
    const [order, setOrder] = useState('asc');
    // eslint-disable-next-line
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openTicket, setOpenTicket] = useState(false);
    const [openNip, setOpenNip] = useState(false);
    const [animacionCarga, setAnimacionCarga] = useState(false);
    const [loadingBotonBuscar, setLoadingBotonBuscar] = useState(false);
    const [loadingBotonImprimir, setLoadingBotonImprimir] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({
        sucursal: "",
        numeroCliente: "",
        codigo: "",
        nombre: "",
        telefono: "",
    });
    const [noSeEncontro, setNoSeEncontro] = useState(false);
    const [sucursales, setSucursales] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [cliente, setCliente] = useState({
        sucursal: "",
        numeroCliente: "",
        codigo: "",
        numeroTarjeta: "",
        nombreCliente: "",
    });
    const [ticket, setTicket] = useState({});
    const [nip, setNip] = useState({
        status: null,
        mensaje: "",
        data: ""
    });

    useEffect(() => {
        getSucursales();
    }, []);

    const getSucursales = async () => {
        setAnimacionCarga(true);
        const result = await obtenerSucursales();
        
        if (result.status) {
            setSucursales(result.data);
        }
        setAnimacionCarga(false);
    }

    const handleOpenMenu = (event, data) => {
        const dataCliente = data.fields;
        dataCliente.id = data.pk;

        setCliente({
            sucursal: form.sucursal,
            numeroCliente: dataCliente.cte,
            codigo: dataCliente.id,
            numeroTarjeta: dataCliente.id,
            nombreCliente: dataCliente.nom,
        });
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setCliente({
            sucursal: "",
            numeroCliente: "",
            codigo: "",
            numeroTarjeta: "",
            nombreCliente: "",
        });
        setOpen(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const handleChangeText = (e) => {
        const { name, value } = e.target;

        setForm({
            ...form,
            [name]: value,
        });
    }

    const handleChangeSucursal = (data) => {
        if (!data) {
            return;
        }

        setForm({
            ...form,
            sucursal: data.fields.num_sucursal,
        });
    }

    const buscar = async () => {
        setNoSeEncontro(false);
        setLoading(true);
        setLoadingBotonBuscar(true);

        let sucursalVacia = false;
        if (form.sucursal === "" || form.sucursal === null) {
            sucursalVacia = true;
        }

        // Si estan vacios regresar
        if (sucursalVacia) {
            setLoadingBotonBuscar(false);
            enqueueSnackbar('Por favor elija una sucursal', { 
                variant: 'error',
                autoHideDuration: 2500,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });

            setLoading(false);
            setLoadingBotonBuscar(false);
            return;
        }

        let result = {}

        if (form.numeroCliente !== "") {
            result = await obtenerClienteNumeroCliente({ numeroCliente: form.numeroCliente });
        }
        else if (form.codigo !== "") {
            result = await obtenerClienteCodigo({ codigo: form.codigo });
        }
        else if (form.nombre !== "") {
            result = await obtenerClienteNombre({ nombre: form.nombre });
        }
        else if (form.telefono !== "") {
            result = await obtenerClienteTelefono({ telefono: form.telefono });
        }
        else {
            enqueueSnackbar('Por favor introduzca datos en algun campo', { 
                variant: 'error',
                autoHideDuration: 2500,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });

            setLoading(false);
            setLoadingBotonBuscar(false);
            return;
        }

        if (result.status) {
            setClientes(result.data);
            setLoading(false);
            setLoadingBotonBuscar(false);
            return;
        }

        setLoading(false);
        setLoadingBotonBuscar(false);
        setNoSeEncontro(true);

        enqueueSnackbar(result.mensaje, { 
            variant: result.status ? 'success' : 'error',
            autoHideDuration: 2500,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        });
    }

    const getTicket = async () => {
        setAnimacionCarga(true);

        const result = await obtenerTicket({ sucursal: cliente.sucursal, cliente: cliente.numeroCliente, numeroTarjeta: cliente.numeroTarjeta, nombreCliente: cliente.nombreCliente });

        if (result.status) {
            setTicket(result.data);
            setAnimacionCarga(false);
            setLoadingBotonBuscar(false);
            return;
        }

        setAnimacionCarga(false);
        setLoadingBotonBuscar(false);
        enqueueSnackbar(result.mensaje, { 
            variant: result.status ? 'success' : 'error',
            autoHideDuration: 2500,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        });
    }

    const getNip = async () => {
        setAnimacionCarga(true);

        const result = await obtenerNipCliente({ codbarras: cliente.numeroTarjeta });

        if (result.status) {
            setNip(result);
            setAnimacionCarga(false);
            setLoadingBotonBuscar(false);
            return;
        }

        setAnimacionCarga(false);
        setLoadingBotonBuscar(false);
        enqueueSnackbar(result.mensaje, { 
            variant: result.status ? 'success' : 'error',
            autoHideDuration: 2500,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        });
    }

    const imprimirTicket = async () => {
        setLoadingBotonImprimir(true);

        const iframe = window.frames.pdf;
        const iframeWindow = iframe.contentWindow;

        console.log(iframe);
        console.log(iframeWindow);
        setTimeout(() => {
            iframe.focus();
            iframeWindow.print();
        }, 500);

        setLoadingBotonImprimir(false);

        // HACER REGISTRO EN LA BD
        await registrarRegistro({ num_tarjeta: cliente.numeroTarjeta, num_cliente: ticket.numeroCliente, usuario: ticket.nombreCliente, num_sucursal: ticket.numSucursal, nombre_sucursal: ticket.sucursal });
        // setOpenTicket(false);
    }

    const abrirVentanaTicket = async () => {
        await getTicket();
        setAnimacionCarga(false);
        setOpen(null);
        setOpenTicket(true);
    }

    const cerrarVentanaTicket = () => {
        setAnimacionCarga(false);
        setOpen(null);
        setOpenTicket(false);
    }

    const abrirVentanaNip = async () => {
        await getNip();
        setAnimacionCarga(false);
        setOpen(null);
        setOpenNip(true);
    }

    const cerrarVentanaNip = () => {
        setAnimacionCarga(false);
        setOpen(null);
        setOpenNip(false);
    }

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - clientes.length) : 0;
    const filteredUsers = applySortFilter(clientes, getComparator(order, orderBy), filterName);
    const isNotFound = !filteredUsers.length && !!filterName;

    return (
        <>
            <Helmet>
                <title> Tickets | Scorpion </title>
            </Helmet>
            {/* Alerta */}
            <SnackbarProvider 
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            />

            {/* Animación de Carga */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={animacionCarga}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* Ver el Ticket */}
            <Dialog onClose={cerrarVentanaTicket} open={openTicket} maxWidth="sm" fullWidth>
                <DialogTitle>
                    <Typography variant="h4" gutterBottom>
                        Ticket
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    {Object.keys(ticket).length !== 0 && (
                        <PDFViewer className='pdf__viewer' id='pdf' style={{display:"none"}}>
                            <PdfTicket
                                ticketData={ticket}
                            />
                        </PDFViewer>
                    )}
                    
                    {Object.keys(ticket).length !== 0 && (
                        <DialogContentText>
                            <center><strong>NÚMERO TARJETA: {ticket.numeroTarjeta}</strong></center>
                            <center><strong>NÚMERO CLIENTE: {ticket.numeroCliente}</strong></center>
                            <center><strong>{ticket.fecha}</strong></center>
                            <center><strong>{ticket.nombreCliente}</strong></center>
                            <center><strong>PREMIUM</strong></center>
                            <br />
                            <center>SUC: {ticket.sucursal}</center>
                            <br />
                            {
                                ticket.promocionesTicket.map((promocion, index) => (
                                    <div key={index}>
                                        <center><strong>{promocion.nombre}</strong></center>
                                        
                                            {promocion.articulos.map((articulo, index) => (
                                                // eslint-disable-next-line
                                                <p key={index} dangerouslySetInnerHTML={{ __html: articulo }} />
                                            ))}
                                            
                                        <br />
                                    </div>
                                ))
                            }
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={cerrarVentanaTicket}>Cancelar</Button>
                    <LoadingButton variant="contained" loading={loadingBotonImprimir} startIcon={<PrintIcon />} onClick={imprimirTicket}>
                        Imprimir
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            {/* Ver el NIP */}
            <Dialog onClose={cerrarVentanaNip} open={openNip} maxWidth="sm">
                <DialogTitle>
                    <Typography variant="h5" gutterBottom>
                        {nip.mensaje}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText mt={2}>
                        <center><Typography variant='h4'>{nip.data}</Typography></center>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={cerrarVentanaNip}>Cerrar</Button>
                </DialogActions>
            </Dialog>

            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Impresión de Tickets
                    </Typography>
                </Stack>

                <Grid container spacing={3} mb={3}>
                    <Grid item xs={12} md={12}>
                        <Card sx={{ p: 3 }}>
                            <Stack spacing={3}>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                    <Grid item xs={12} sm={3.9} spacing={0.5}>
                                        <AutocompleteData sucursales={sucursales} handleChange={handleChangeSucursal} />
                                    </Grid>
                                </Stack>

                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                    <TextField
                                        id="numeroCliente"
                                        name="numeroCliente"
                                        label="Número de Cliente"
                                        variant="outlined"
                                        fullWidth
                                        value={form.numeroCliente}
                                        onChange={handleChangeText}
                                    />
                                    <TextField
                                        id="codigo"
                                        name="codigo"
                                        label="Código"
                                        variant="outlined"
                                        fullWidth
                                        value={form.codigo}
                                        onChange={handleChangeText}
                                    />

                                    <TextField
                                        id="nombre"
                                        name="nombre"
                                        label="Nombre de Cliente"
                                        variant="outlined"
                                        fullWidth
                                        value={form.nombre}
                                        onChange={handleChangeText}
                                    />
                                    <TextField
                                        id="telefono"
                                        name="telefono"
                                        label="Teléfono"
                                        variant="outlined"
                                        fullWidth
                                        value={form.telefono}
                                        onChange={handleChangeText}
                                    />
                                </Stack>

                                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                    <LoadingButton variant="contained" loading={loadingBotonBuscar} onClick={buscar}>
                                        Buscar
                                    </LoadingButton>
                                </Box>
                            </Stack>
                        </Card>
                    </Grid>
                </Grid>

                <Card>
                    <BuscadorClientes filterName={filterName} onFilterName={handleFilterByName} />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <CabeceraTabla headLabel={TABLE_HEAD} />
                                <TableBody>
                                    {filteredUsers
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        // eslint-disable-next-line
                                        .map((cliente) => {
                                            const { cte, nom, tel1 } = cliente.fields;

                                            return (
                                                <TableRow hover key={cliente.pk}>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {cte}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {cliente.pk}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {nom}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {fPhoneNumber2(tel1)}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <IconButton
                                                            size="large"
                                                            color="inherit"
                                                            onClick={(e) => handleOpenMenu(e, cliente)}
                                                        >
                                                            <Iconify icon={'eva:more-vertical-fill'} />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 77 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={4} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        No encontrado
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No hay resultados para &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Intente con otro nombre.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}

                                {noSeEncontro && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={4} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        No encontrado
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No se encontro el Cliente
                                                        <br />
                                                        Por favor asegurese de que su información se correcta.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}

                                {loading && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={9} sx={{ py: 9 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <LoadingScreen />
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10]}
                        component="div"
                        count={clientes.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 180,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={abrirVentanaTicket}>
                    <Iconify icon={'mdi:eye-outline'} sx={{ mr: 2 }} />
                    Ver Ticket
                </MenuItem>
                <MenuItem onClick={abrirVentanaNip}>
                    <Iconify icon={'mdi:eye-outline'} sx={{ mr: 2 }} />
                    Ver Nip
                </MenuItem>
            </Popover>
        </>
    );
}
