import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { filter } from 'lodash';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Button,
    Popover,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import { useTheme } from '@material-ui/core/styles';
import { LoadingButton } from '@material-ui/lab';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

// components
import LoadingScreen from '../../../components/loading/LoadingScreen';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import Label from '../../../components/LabelText';

// context
import useSucursal from '../../../hooks/administrador/useSucursal';

import CabeceraTabla from './CabeceraTabla';
import BuscadorNumero from './BuscadorNumero';
import BuscadorNombre from './BuscadorNombre';

// Servicios
// import { obtenerGrupos, eliminarGrupo as eliminar } from '../../../services/administrador/grupos';
import { obtenerSucursales, eliminarSesionSucursal, eliminarSucursal as eliminar } from '../../../services/administrador/sucursales';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'id', label: 'Id', alignRight: false },
    { id: 'num_sucursal', label: 'Número de Sucursal', alignRight: false },
    { id: 'nombre', label: 'Nombre', alignRight: false },
    { id: 'sesion', label: 'Sesión 1', alignRight: false },
    { id: 'sesion_2', label: 'Sesión 2', alignRight: false },
    { id: 'sesion_3', label: 'Sesión 3', alignRight: false },
    { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, queryName, queryNum) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (queryName) {
        return filter(array, (_user) => _user.fields.nombre_sucursal.toLowerCase().indexOf(queryName.toLowerCase()) !== -1);
    }
    if (queryNum) {
        return filter(array, (_user) => _user.fields.num_sucursal.toLowerCase().indexOf(queryNum.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function AdministradorSucursales() {
    const { onChangeSucursal } = useSucursal();
    const theme = useTheme();
    const navigate = useNavigate();
    const [sucursales, setSucursales] = useState([]);
    const [sucursal, setSucursal] = useState({});
    const [open, setOpen] = useState(null);
    const [page, setPage] = useState(0);
    // eslint-disable-next-line
    const [order, setOrder] = useState('asc');
    // eslint-disable-next-line
    const [orderBy, setOrderBy] = useState('name');
    const [filterNum, setFilterNum] = useState('');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openEliminar, setOpenEliminar] = useState(false);
    const [openEliminarSucursal, setOpenEliminarSucursal] = useState(false);
    const [loadingBoton, setLoadingBoton] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sesion, setSesion] = useState(0);

    useEffect(() => {
        getSucursales();
    }, []);

    const getSucursales = async () => {
        setLoading(true);
        const result = await obtenerSucursales();
        
        if (result.status) {
            setSucursales(result.data);
        }
        setLoading(false);
    }

    const handleOpenMenu = (event, data) => {
        const dataSucursal = data.fields;
        dataSucursal.id = data.pk;
        setSucursal(dataSucursal);
        setOpen(event.currentTarget);
    }

    const handleCloseMenu = () => {
        setSucursal({});
        setOpen(null);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    }

    const handleFilterByNumber = (event) => {
        setPage(0);
        setFilterNum(event.target.value);
    }

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    }

    const crearSucursal = () => {
        navigate('/administrador/sucursales/crear', { replace: true });
    }

    const editarSucursal = () => {
        onChangeSucursal(sucursal);
        navigate('/administrador/sucursales/editar', { replace: true });
    }

    const abrirVentanaEliminar = (numSesion) => {
        setSesion(numSesion);
        setOpen(null);
        setOpenEliminar(true);
    }

    const cerrarVentanaEliminar = () => {
        setOpen(null);
        setOpenEliminar(false);
    }

    const eliminarSesion = async () => {
        setLoadingBoton(true);
        
        const result = await eliminarSesionSucursal({ "id": sucursal.id, "numSesion": sesion });

        if (result.status) {
            setSucursales([]);
            getSucursales();
        }

        setLoadingBoton(false);
        setOpenEliminar(false);

        enqueueSnackbar(result.mensaje, { 
            variant: result.status ? 'success' : 'error',
            autoHideDuration: 3000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        });
    }

    const abrirVentanaEliminarSucursal = () => {
        setOpen(null);
        setOpenEliminarSucursal(true);
    }

    const cerrarVentanaEliminarSucursal = () => {
        setOpen(null);
        setOpenEliminarSucursal(false);
    }

    const eliminarSucursal = async () => {
        setLoadingBoton(true);
        
        const result = await eliminar(sucursal);

        if (result.status) {
            getSucursales();
        }

        setLoadingBoton(false);
        setOpenEliminarSucursal(false);

        enqueueSnackbar(result.mensaje, { 
            variant: result.status ? 'success' : 'error',
            autoHideDuration: 3000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        });
    }

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sucursales.length) : 0;
    const filteredUsers = applySortFilter(sucursales, getComparator(order, orderBy), filterName, filterNum);
    const isNotFound = !filteredUsers.length && !!filterName || !filteredUsers.length && !!filterNum;

    return (
        <>
            <Helmet>
                <title> Sucursales | Scorpion </title>
            </Helmet>
            {/* Alerta */}
            <SnackbarProvider 
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            />

            {/* Venta de eliminar Sesión */}
            <Dialog onClose={cerrarVentanaEliminar} open={openEliminar} maxWidth="sm" fullWidth>
                <DialogTitle>
                    <Typography variant="h4" gutterBottom>
                        Terminar Sesión
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Estás seguro de terminar la Sesión?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={cerrarVentanaEliminar}>Cancelar</Button>
                    <LoadingButton variant="contained" color="error" loading={loadingBoton} onClick={eliminarSesion}>
                        Terminar
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            {/* Venta de eliminar Sucursal */}
            <Dialog onClose={cerrarVentanaEliminarSucursal} open={openEliminarSucursal} maxWidth="sm" fullWidth>
                <DialogTitle>
                    <Typography variant="h4" gutterBottom>
                        Eliminar Sucursal
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Estás seguro de eliminar la Sucursal?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={cerrarVentanaEliminarSucursal}>Cancelar</Button>
                    <LoadingButton variant="contained" color="error" loading={loadingBoton} onClick={eliminarSucursal}>
                        Eliminar
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Sucursales
                    </Typography>
                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={crearSucursal}>
                        Nueva Sucursal
                    </Button>
                </Stack>

                <Card>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <BuscadorNumero filterName={filterNum} onFilterName={handleFilterByNumber} />
                        <BuscadorNombre filterName={filterName} onFilterName={handleFilterByName} />
                    </Stack>

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <CabeceraTabla headLabel={TABLE_HEAD} />
                                <TableBody>
                                    {filteredUsers
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        // eslint-disable-next-line
                                        .map((sucursal) => {
                                            const { num_sucursal, nombre_sucursal, token, token_2, token_3 } = sucursal.fields;

                                            return (
                                                <TableRow hover key={sucursal.pk}>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {sucursal.pk}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {num_sucursal}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {nombre_sucursal}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Label
                                                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                                                            color={(token === null && 'error') || 'success'}
                                                        >
                                                            {token !== null ? "Activa" : "Inactiva"}
                                                        </Label>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Label
                                                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                                                            color={(token_2 === null && 'error') || 'success'}
                                                        >
                                                            {token_2 !== null ? "Activa" : "Inactiva"}
                                                        </Label>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Label
                                                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                                                            color={(token_3 === null && 'error') || 'success'}
                                                        >
                                                            {token_3 !== null ? "Activa" : "Inactiva"}
                                                        </Label>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <IconButton
                                                            size="large"
                                                            color="inherit"
                                                            onClick={(e) => handleOpenMenu(e, sucursal)}
                                                        >
                                                            <Iconify icon={'eva:more-vertical-fill'} />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 77 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={4} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        No encontrado
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        Intente con otra sucursal.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}

                                {loading && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={9} sx={{ py: 9 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <LoadingScreen />
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10]}
                        component="div"
                        count={sucursales.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        // width: 210,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={editarSucursal}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Editar Sucursal
                </MenuItem>

                <MenuItem onClick={() => abrirVentanaEliminar(1)} sx={{ color: 'error.main' }}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Terminar Sesión 1
                </MenuItem>

                <MenuItem onClick={() => abrirVentanaEliminar(2)} sx={{ color: 'error.main' }}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Terminar Sesión 2
                </MenuItem>

                <MenuItem onClick={() => abrirVentanaEliminar(3)} sx={{ color: 'error.main' }}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Terminar Sesión 3
                </MenuItem>

                <MenuItem onClick={() => abrirVentanaEliminarSucursal()} sx={{ color: 'error.main' }}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Eliminar Sucursal
                </MenuItem>
            </Popover>
        </>
    );
}
