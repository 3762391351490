import PropTypes from 'prop-types';
import {
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';

FormUsuarios.propTypes = {
    open: PropTypes.bool,
    cerrarForm: PropTypes.func,
    usuariosSinUrl: PropTypes.string
};

function FormUsuarios({ open, cerrarForm, usuariosSinUrl }) {

    return (
        <Dialog onClose={cerrarForm} open={open} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Typography variant="h4" gutterBottom>
                    Usuarios sin Url
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Usuarios que aún no cuentan con una Url son <strong>{usuariosSinUrl}</strong> por favor de esperar un tiempo para que se genere su Url.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={cerrarForm}>Aceptar</Button>
            </DialogActions>
        </Dialog>
    );
}

export default FormUsuarios;
