import axios from 'axios';
import { to } from 'await-to-js';

const { REACT_APP_API_URL } = process.env;

export async function crearUsuariosURL(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/usuarios-url/crear`, payload, {timeout: 1800000}));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function descargarExcelUsuariosURL(){
    try {
        const response = await axios.get(`${REACT_APP_API_URL}/administrador/usuarios-url/descargar`, {
            responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'UsuariosUrl.xls');
        document.body.appendChild(link);
        link.click();
        link.remove();
    } catch (error) {
        console.error('Error al descargar el archivo:', error);
    }
}

export async function obtenerUsuariosSinURL() {
    const [error, response] = await to(axios.get(`${REACT_APP_API_URL}/administrador/usuarios-url/obtener_sin_url`));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}
