import { Navigate, useRoutes } from 'react-router-dom';
// layout
import SimpleLayout from '../layouts/simple';

// --------------------------------------- LOGIN ---------------------------------------
// *************************************************************************************
import Login from '../pages/login';

// ----------------------------------- ADMINISTRADOR -----------------------------------
// *************************************************************************************
// LAYOUT
import AdministradorLayout from '../layouts/administrador';

// GRUPOS
import AdministradorGrupos from '../pages/administrador/grupos';
import { AdministradorGrupoProvider } from '../context/administrador/AdministradorGrupoContext';
import AdministradorCrearEditarGrupo from '../pages/administrador/grupos/CrearEditarGrupo';

// USUARIOS
import AdministradorUsuarios from '../pages/administrador/usuarios';
import { AdministradorUsuarioProvider } from '../context/administrador/AdministradorUsuarioContext';
import AdministradorCrearEditarUsuario from '../pages/administrador/usuarios/CrearEditarUsuario';

// USUARIOS URL
import AdministradorUsuariosURL from '../pages/administrador/usuarios_url';

// TICKETS
import AdministradorTickets from '../pages/administrador/tickets';

// HISTORIAL
import AdministradorHistorial from '../pages/administrador/historial';

// SUCURSALES
import AdministradorSucursales from '../pages/administrador/sucursales';
import { AdministradorSucursalProvider } from '../context/administrador/AdministradorSucursalContext';
import AdministradorEditarSucursal from '../pages/administrador/sucursales/EditarSucursal';

// PROVEEDORES
import AdministradorProveedores from '../pages/administrador/proveedores';

// ARTICULOS
import AdministradorArticulos from '../pages/administrador/articulos';

// PROMOCIONES POR PUNTOS
import AdministradorPromocionesPuntos from '../pages/administrador/promociones_puntos';
import { AdministradorPromocionPuntoProvider } from '../context/administrador/AdministradorPromocionPuntoContext';
import AdministradorCrearEditarPromocionPunto from '../pages/administrador/promociones_puntos/CrearEditarPromocionPunto';

// COMPRA GANA
import AdministradorCompraGana from '../pages/administrador/compra-gana';

// PUNTOS LEALTAD
import AdministradorPesosLealtad from '../pages/administrador/pesos-lealtad';

// CONFIGURACION
import AdministradorConfiguracion from '../pages/administrador/configuracion';

// -------------------------------------- GENERAL --------------------------------------
// *************************************************************************************
// LAYOUT
import GeneralLayout from '../layouts/general';

// USUARIOS
import GeneralUsuarios from '../pages/general/usuarios';
// import GeneralCrearEditarUsuario from '../pages/general/usuarios/CrearEditarUsuario';

// TICKETS
import GeneralTickets from '../pages/general/tickets';

// HISTORIAL
import GeneralHistorial from '../pages/general/historial';

// SUCURSALES
import GeneralSucursales from '../pages/general/sucursales';

// PROVEEDORES
import GeneralProveedores from '../pages/general/proveedores';

// ARTICULOS
import GeneralArticulos from '../pages/general/articulos';

// CONFIGURACION
import GeneralConfiguracion from '../pages/general/configuracion';

// -------------------------------------- USUARIO --------------------------------------
// *************************************************************************************
// LAYOUT
import UsuarioLayout from '../layouts/usuario';

// TICKETS
import UsuarioTickets from '../pages/usuario/tickets';

// HISTORIAL
import UsuarioHistorial from '../pages/usuario/historial';

// CONFIGURACION
import UsuarioConfiguracion from '../pages/usuario/configuracion';

import Page404 from '../pages/Page404';

// AUTH
import { isLogin, typeUser } from '../auth';

// ----------------------------------------------------------------------
// Usuarios
// (Tiendas, Usuario de consultas, Usuario con privilegios de alta de usuarios)
export default function Router() {
    const login = isLogin();
    const tipoUsuario = typeUser();

    const routes = useRoutes([
        {
            path: '/',
            element: !login ? <Login /> : <Navigate to={`/${tipoUsuario}`} />,
        },
        {
            path: '/administrador',
            element: login && tipoUsuario === 'administrador' ? <AdministradorLayout /> : <Navigate to="/" />,
            children: [
                { element: <Navigate to="/administrador/grupos" />, index: true },
                { path: 'grupos', element: <AdministradorGrupoProvider><AdministradorGrupos /></AdministradorGrupoProvider> },
                { path: 'grupos/crear', element: <AdministradorGrupoProvider><AdministradorCrearEditarGrupo /></AdministradorGrupoProvider> },
                { path: 'grupos/editar', element: <AdministradorGrupoProvider><AdministradorCrearEditarGrupo /></AdministradorGrupoProvider> },

                { path: 'usuarios', element: <AdministradorUsuarioProvider><AdministradorUsuarios /></AdministradorUsuarioProvider> },
                { path: 'usuarios/crear', element: <AdministradorUsuarioProvider><AdministradorCrearEditarUsuario /></AdministradorUsuarioProvider> },
                { path: 'usuarios/editar', element: <AdministradorUsuarioProvider><AdministradorCrearEditarUsuario /></AdministradorUsuarioProvider> },

                { path: 'usuarios-url', element: <AdministradorUsuariosURL /> },

                { path: 'tickets', element: <AdministradorTickets /> },

                { path: 'historial', element: <AdministradorHistorial /> },

                { path: 'sucursales', element: <AdministradorSucursalProvider><AdministradorSucursales /></AdministradorSucursalProvider> },
                { path: 'sucursales/crear', element: <AdministradorSucursalProvider><AdministradorEditarSucursal /></AdministradorSucursalProvider> },
                { path: 'sucursales/editar', element: <AdministradorSucursalProvider><AdministradorEditarSucursal /></AdministradorSucursalProvider> },

                { path: 'proveedores', element: <AdministradorProveedores /> },

                { path: 'articulos', element: <AdministradorArticulos /> },

                // { path: 'promociones-puntos', element: <AdministradorPromocionPuntoProvider><AdministradorPromocionesPuntos /></AdministradorPromocionPuntoProvider> },
                // { path: 'promociones-puntos/crear', element: <AdministradorPromocionPuntoProvider><AdministradorCrearEditarPromocionPunto/></AdministradorPromocionPuntoProvider> },
                // { path: 'promociones-puntos/editar', element: <AdministradorPromocionPuntoProvider><AdministradorCrearEditarPromocionPunto/></AdministradorPromocionPuntoProvider> },

                { path: 'pesos-lealtad', element: <AdministradorPesosLealtad /> },

                { path: 'compra-gana', element: <AdministradorCompraGana /> },

                { path: 'configuracion', element: <AdministradorConfiguracion /> },
            ],
        },
        {
            path: '/general',
            element: login && tipoUsuario === 'general' ? <GeneralLayout /> : <Navigate to="/" />,
            children: [
                { element: <Navigate to="/general/usuarios" />, index: true },
                { path: 'usuarios', element: <GeneralUsuarios /> },

                { path: 'tickets', element: <GeneralTickets /> },

                { path: 'historial', element: <GeneralHistorial /> },

                { path: 'sucursales', element: <GeneralSucursales /> },

                { path: 'proveedores', element: <GeneralProveedores /> },

                { path: 'articulos', element: <GeneralArticulos /> },

                { path: 'configuracion', element: <GeneralConfiguracion /> },
            ],
        },
        {
            path: '/usuario',
            element: login && tipoUsuario === 'usuario' ? <UsuarioLayout /> : <Navigate to="/" />,
            children: [
                { element: <Navigate to="/usuario/tickets" />, index: true },
                { path: 'tickets', element: <UsuarioTickets /> },

                { path: 'historial', element: <UsuarioHistorial /> },

                { path: 'configuracion', element: <UsuarioConfiguracion /> },
            ],
        },
        {
            element: <SimpleLayout />,
            children: [
                { element: <Navigate to="/administrador/app" /> },
                { path: '404', element: <Page404 /> },
                { path: '*', element: <Navigate to="/404" /> },
            ],
        },
        {
            path: '*',
            element: <Navigate to="/404" replace />,
        },
    ]);

    return routes;
}
