import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// material
import { LoadingButton } from '@material-ui/lab';
import {
    Box,
    Card,
    Container,
    Button,
    Grid,
    Stack,
    TextField,
    Typography,
    IconButton,
    InputAdornment,
} from '@material-ui/core';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import Iconify from '../../../components/iconify';

// import ColorPicker from '../../../components/color-picker/ColorPicker';

// context
import useSucursal from '../../../hooks/administrador/useSucursal';

// services
import { crearSucursal, editarSucursalPassword as editar } from '../../../services/administrador/sucursales';

// ----------------------------------------------------------------------

FormSucursal.propTypes = {
    isEdit: PropTypes.bool,
    sucursal: PropTypes.object
};

export default function FormSucursal({ isEdit, sucursal }) {
    const navigate = useNavigate();
    const { onChangeSucursal } = useSucursal();
    const [showPassword, setShowPassword] = useState(false);
    const [loadingBoton, setLoadingBoton] = useState(false);
    const [form, setForm] = useState({
        id: "",
        num_sucursal: "",
        nombre_sucursal: "",
        direccion: "",
        telefono: "",
        latitud: "",
        longitud: "",
        horario: "",
        password: "",
        new_password: "",
    });

    useEffect(() => {
        if (Object.keys(sucursal).length !== 0) {
            setForm({
                id: sucursal.id,
                num_sucursal: sucursal.num_sucursal,
                nombre_sucursal: sucursal.nombre_sucursal,
                direccion: sucursal.direccion,
                telefono: sucursal.telefono,
                latitud: sucursal.latitud,
                longitud: sucursal.longitud,
                horario: sucursal.horario,
                password: sucursal.password,
                new_password: "",
            });
        }
    }, [sucursal]);

    const handleChangeText = (e) => {
        const { name, value } = e.target;

        setForm({
            ...form,
            [name]: value,
        });
    }

    const cancelar = () => {
        onChangeSucursal({});
        navigate('/administrador/sucursales', { replace: true });
    }

    const crearEditarSucursal = async () => {
        setLoadingBoton(true);

        // // Checar que los campos no esten vacios
        // let vacio = false;
        // if (form.new_password === "" || form.new_password === null) {
        //     vacio = true;
        // }

        // // Si estan vacios regresar
        // if (vacio) {
        //     setLoadingBoton(false);
        //     enqueueSnackbar('Por favor introduzca la contraseña', { 
        //         variant: 'error',
        //         autoHideDuration: 2500,
        //         anchorOrigin: {
        //             vertical: 'top',
        //             horizontal: 'right'
        //         }
        //     });

        //     return;
        // }

        form.password = form.new_password;
        let result = {}

        if(isEdit) {
            result = await editar(form);
        } else {
            result = await crearSucursal(form);
        }
        
        setLoadingBoton(false);
        enqueueSnackbar(result.mensaje, { 
            variant: result.status ? 'success' : 'error',
            autoHideDuration: 5000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        });

        if (result.status) {
            onChangeSucursal({});
            setTimeout(() => {
                navigate('/administrador/sucursales', { replace: true });
            }, 3000);
        }
    }

    return (
        <Container>
            {/* Alerta */}
            <SnackbarProvider 
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            />

            <Typography variant="h4" mb={3}>
                {isEdit ? 'Editar Sucursal' : 'Crear Sucursal'}
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Card sx={{ p: 3 }}>
                        <Stack spacing={3}>
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                <TextField
                                    id="num_sucursal"
                                    name="num_sucursal"
                                    label="Número de Sucursal"
                                    autoComplete="off"
                                    variant="outlined"
                                    fullWidth
                                    value={form.num_sucursal}
                                    onChange={handleChangeText}
                                />
                                <TextField
                                    id="nombre_sucursal"
                                    name="nombre_sucursal"
                                    label="Nombre de Sucursal"
                                    autoComplete="off"
                                    variant="outlined"
                                    fullWidth
                                    value={form.nombre_sucursal}
                                    onChange={handleChangeText}
                                />
                            </Stack>
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                <TextField
                                    id="direccion"
                                    name="direccion"
                                    label="Dirección"
                                    autoComplete="off"
                                    variant="outlined"
                                    fullWidth
                                    value={form.direccion}
                                    onChange={handleChangeText}
                                />
                                <TextField
                                    id="telefono"
                                    name="telefono"
                                    label="Teléfono"
                                    autoComplete="off"
                                    variant="outlined"
                                    fullWidth
                                    value={form.telefono}
                                    onChange={handleChangeText}
                                />
                            </Stack>
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                <TextField
                                    id="latitud"
                                    name="latitud"
                                    label="Latitud"
                                    autoComplete="off"
                                    variant="outlined"
                                    fullWidth
                                    value={form.latitud}
                                    onChange={handleChangeText}
                                />
                                <TextField
                                    id="longitud"
                                    name="longitud"
                                    label="Longitud"
                                    autoComplete="off"
                                    variant="outlined"
                                    fullWidth
                                    value={form.longitud}
                                    onChange={handleChangeText}
                                />
                            </Stack>
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                <TextField
                                    id="horario"
                                    name="horario"
                                    label="Horario"
                                    autoComplete="off"
                                    variant="outlined"
                                    fullWidth
                                    value={form.horario}
                                    onChange={handleChangeText}
                                />
                                <TextField
                                    id="new_password"
                                    name="new_password"
                                    label={isEdit ? 'Cambiar Contraseña' : 'Contraseña'}
                                    autoComplete="off"
                                    type={showPassword ? 'text' : 'password'}
                                    value={form.new_password}
                                    fullWidth
                                    onChange={handleChangeText}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Stack>

                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    style={{ marginRight: '15px' }}
                                    variant="outlined"
                                    onClick={cancelar}
                                >
                                    Cancelar
                                </Button>
                                <LoadingButton variant="contained" loading={loadingBoton} onClick={crearEditarSucursal}>
                                    {!isEdit ? 'Crear Sucursal' : 'Guardar Cambios'}
                                </LoadingButton>
                            </Box>
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}
