import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Grid,
    Stack,
    Container,
    Typography,
    Button,
} from '@mui/material';
import { SnackbarProvider } from 'notistack';

// components
import ExcelIcon from '../../../components/excel-icon';
import FormArchivo from './FormArchivo';
import FormUsuarios from './FormUsuarios';

import { obtenerUsuariosSinURL, descargarExcelUsuariosURL } from '../../../services/administrador/usuariosURL';

// ----------------------------------------------------------------------

export default function AdministradorUsuariosURL() {
    const [openForm, setOpenForm] = useState(false);
    const [usuariosSinUrl, setUsuariosSinUrl] = useState("");
    const [openFormUsuario, setOpenFormUsuario] = useState(false);

    const abrirFormArchivo = () => {
        setOpenForm(true);
    }
    
    const cerrarFormArchivo = () => {
        setOpenForm(false);
    }

    const abrirFormUsuario = () => {
        setOpenFormUsuario(true);
    }
    
    const cerrarFormUsuario = () => {
        setOpenFormUsuario(false);
    }

    const descargarArchivo = async () => {
        const result = await obtenerUsuariosSinURL();
        setUsuariosSinUrl(result.usuariosSinUrl);

        if (result.usuariosSinUrl > 0) {
            abrirFormUsuario();
        }
        await descargarExcelUsuariosURL();
    }

    return (
        <>
            <Helmet>
                <title> Usuarios URL | Scorpion </title>
            </Helmet>

            {/* Alerta */}
            <SnackbarProvider 
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            />

            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography variant="h4" gutterBottom>
                        Usuarios URL
                    </Typography>
                </Stack>

                <Container maxWidth="lg">
                    
                    {/* Form Archivo */}
                    <FormArchivo open={openForm} cerrarForm={cerrarFormArchivo} />

                    {/* Form Usuario */}
                    <FormUsuarios open={openFormUsuario} cerrarForm={cerrarFormUsuario} usuariosSinUrl={usuariosSinUrl} />

                    <Card>
                        <Grid style={{ padding: '20px' }} mt={6} mb={6}>
                            <Stack alignItems="center" justifyContent="space-between">
                                <Stack alignItems="center" justifyContent="center">
                                    <Typography variant='h4'>Registra a tus Usuarios</Typography>
                                    <Typography variant='body1' mt={7} mb={7}>Registra los Usuarios para crear su URL para que puedan visualizar su Ticket, Puntos de Lealtad y Los Compra y Gana en el Micro Sitio.</Typography>
                                </Stack>
                                <Stack alignItems="center" justifyContent="center">
                                    <Button variant='contained' onClick={abrirFormArchivo} startIcon={<ExcelIcon />}>
                                        Subir Usuarios
                                    </Button>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Card>

                    <Card style={{ marginTop: '20px' }}>
                        <Grid style={{ padding: '20px' }} mt={6} mb={6}>
                            <Stack alignItems="center" justifyContent="space-between">
                                <Stack alignItems="center" justifyContent="center">
                                    <Typography variant='h4'>Descarga a tus Usuarios</Typography>
                                    <Typography variant='body1' mt={7} mb={7}>Descarga los Usuarios que ya cuenten con URL corta para que puedan visualizar su Ticket, Puntos de Lealtad y Los Compra y Gana en el Micro Sitio.</Typography>
                                </Stack>
                                <Stack alignItems="center" justifyContent="center">
                                    <Button variant='contained' onClick={descargarArchivo} startIcon={<ExcelIcon />}>
                                        Descargar Usuarios
                                    </Button>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Card>
                </Container>
            </Container>
        </>
    );
}
