import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// material
import { Container } from '@material-ui/core';

// context
import useSucursal from '../../../hooks/administrador/useSucursal';

// components
import FormSucursal from './FormSucursal';

// ----------------------------------------------------------------------

export default function EditarSucursal() {
    const { sucursal } = useSucursal();
    const { pathname } = useLocation();
    const isEdit = pathname.includes('editar');
    const currentUser = sucursal;

    return (
        <>
            <Helmet>
                <title> {!isEdit ? 'Crear Sucursal' : 'Editar Sucursal'} | Scorpion </title>
            </Helmet>
        
            <Container maxWidth="xl">
                <FormSucursal isEdit={isEdit} sucursal={currentUser} />
            </Container>
        </>
    );
}
