import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fNumber(number) {
    return numeral(number).format();
}

export function fCurrency(number) {
    const format = number ? numeral(number).format('$0,0.00') : '';

    return result(format, '.00');
}

export function fPercent(number) {
    const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

    return result(format, '.0');
}

export function fShortenNumber(number) {
    const format = number ? numeral(number).format('0.00a') : '';

    return result(format, '.00');
}

export function fData(number) {
    const format = number ? numeral(number).format('0.0 b') : '';

    return result(format, '.0');
}

export function fPhoneNumber(number) {
    // eslint-disable-next-line
    const cleaned = ('' + number).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{2})(\d{2})(\d{4})(\d{4})$/);
    if (match) {
        return `(+${match[1]}) ${match[2]}-${match[3]}-${match[4]}`
    }
    return number;
}

export function fPhoneNumber2(number) {
    // eslint-disable-next-line
    const cleaned = ('' + number).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
    if (match) {
        return `${match[1]}-${match[2]}-${match[3]}`
    }
    return number;
}

function result(format, key = '.00') {
    const isInteger = format.includes(key);

    return isInteger ? format.replace(key, '') : format;
}
