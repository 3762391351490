import axios from 'axios';
import { to } from 'await-to-js';

const { REACT_APP_API_URL } = process.env;

export async function obtenerSucursales() {
    const [error, response] = await to(axios.get(`${REACT_APP_API_URL}/administrador/tickets/obtener_sucursales`));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function obtenerClienteNumeroCliente(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/tickets/obtener_cliente_numero_cliente`, payload));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function obtenerClienteCodigo(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/tickets/obtener_cliente_codigo`, payload));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function obtenerClienteNombre(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/tickets/obtener_cliente_nombre`, payload));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function obtenerClienteTelefono(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/tickets/obtener_cliente_telefono`, payload));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function obtenerTicket(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/tickets/obtener_tickets_sucursal_cliente`, payload));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function obtenerNipCliente(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/tickets/obtener_nip_cliente`, payload));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function registrarRegistro(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/tickets/registrar_registro`, payload));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function obtenerHistorial() {
    const [error, response] = await to(axios.get(`${REACT_APP_API_URL}/administrador/tickets/historial`));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}
